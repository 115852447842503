import { Grid } from "@mui/material";
import { CentreAsyncSelectForm, TextInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC, useMemo } from "react";

import { UtilitaireModal } from "../UtilitaireModal";
import { coffreFortService } from "services";
import { useQueryClient } from "@tanstack/react-query";
import { coffreFortConverter, coffreFortSchema } from "./coffreFortUtils";
import { CoffreFortResource } from "types/caisse.type";
import { useFormContext } from "react-hook-form";

const Form: FC<WrapperProps> = (props) => {
  const { setValue } = useFormContext();
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title="Gestion du coffre fort"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CentreAsyncSelectForm
            label="Centre *"
            name="centre"
            afterSelected={(_, centre) => {
              if (centre) {
                setValue("libelle", "Coffre fort " + centre.label);
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput label="Libellé *" name="libelle" />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const CoffreFortForm = withForm(Form, coffreFortSchema);

type CoffreFortModalProps = {
  closeModal: () => void;
  value?: CoffreFortResource;
};

export const CoffreFortModal: FC<CoffreFortModalProps> = ({
  closeModal,
  value,
}) => {
  const query = useQueryClient();

  const onSave = (data: any) => {
    const body = coffreFortConverter.toBody(data);
    if (value?.id) {
      return coffreFortService.update(value.id, body);
    }
    return coffreFortService.create(body);
  };

  const onFinished = () => {
    query.invalidateQueries(["coffreforts"]);
    closeModal();
  };

  const defaultValues = useMemo(() => {
    if (value) {
      return coffreFortConverter.toInput(value);
    }
    return {
      limitable: "0",
      activite: "maintenance",
    };
  }, [value]);

  return (
    <CoffreFortForm
      onBack={closeModal}
      onFinished={onFinished}
      onSave={onSave}
      defaultValues={defaultValues}
    />
  );
};
