import { CoffreFortResource } from "types/caisse.type";
import { selectHelper } from "utils/helpers";
import * as yup from "yup";

export const coffreFortSchema = yup.object({
  libelle: yup.string().required(),
  centre: yup.object().required(),
});

const toBody = (data: Record<string, any>) => {
  return {
    libelle: data.libelle,
    centre_id: selectHelper.getValue(data.centre),
  };
};

const toInput = (data: CoffreFortResource) => {
  return {
    libelle: data.libelle,
    centre: selectHelper.buildCentre(data.centre),
  };
};

export const coffreFortConverter = {
  toBody,
  toInput,
};
