import { Box } from "@mui/material";
import { MontantFormatText, SousColisPopover } from "components";
import { CellPopover, Table } from "components/table";
import { ViewListItemEmpty } from "pages/common";
import { FC, useMemo } from "react";
import { ColisResource } from "types/transport.types";
import { ColisActions } from "../common";

type ListDesserteColisProps = {
  colis?: ColisResource[];
  updateColis?: (colis: ColisResource) => void;
  canEdit?: boolean;
};

export const BilleteriePopover: FC<{
  detailBillets?: any[];
  detailPieces?: any[];
}> = ({ detailBillets, detailPieces }) => {
  const data = useMemo(() => {
    return [...(detailBillets || []), ...(detailPieces || [])];
  }, [detailBillets, detailPieces]);

  return (
    <>
      <CellPopover name="colis">
        <Table
          size="small"
          columns={[
            {
              Header: "Quantite",
              accessor: "quantite",
              Cell: ({ row: { original } }: any) => (
                <MontantFormatText value={original.quantite} />
              ),
            },
            {
              Header: "Valeur",
              accessor: "valeur",
              Cell: ({ row: { original } }: any) => (
                <MontantFormatText value={original.valeur} />
              ),
            },
            {
              Header: "Montant",
              accessor: "montant",
              Cell: ({ row: { original } }: any) => (
                <MontantFormatText
                  value={
                    parseInt(original.valeur) * parseInt(original.quantite)
                  }
                />
              ),
            },
          ]}
          data={data.filter((e) => e.valeur || e.quantite)}
        />
      </CellPopover>
    </>
  );
};

export const ListDesserteColis: FC<ListDesserteColisProps> = ({
  colis,
  updateColis,
  canEdit,
}) => {
  const columns = useMemo(() => {
    const cols = [
      {
        Header: "Numero",
        accessor: "numero",
      },
      {
        Header: "Montant",
        accessor: "montant",
        Cell: ({ row: { original } }: any) => (
          <MontantFormatText value={original.montant} />
        ),
      },
      {
        Header: "Sous colis",
        accessor: "colis",
        Cell: ({ row: { original } }: any) => (
          <SousColisPopover sousColis={original.sousColis} />
        ),
      },
      {
        Header: "Billeteries",
        accessor: "billeteries",
        Cell: ({ row: { original } }: any) => (
          <BilleteriePopover
            detailBillets={original.detailBillets}
            detailPieces={original.detailPieces}
          />
        ),
      },
    ];

    if (updateColis && canEdit) {
      cols.push({
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row: { original } }: any) => (
          <Box textAlign="right">
            <ColisActions onFinished={updateColis} colis={original} />
          </Box>
        ),
      });
    }

    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canEdit]);

  return (
    <>
      {!colis?.length ? (
        <ViewListItemEmpty text="Colis non renseigné" />
      ) : (
        <Table columns={columns} data={colis || []} />
      )}
    </>
  );
};
