import { CODE_FONCTION } from "types";

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

const PERSONNELS = "/personnels";
const CLIENTS = "/clients";
const SITES = "/sites";
const VEHICULES = "/vehicules";
const TOURNEES = "/tournees";
const REPORTING = "/reporting";
const FACTURES = "/factures";
const COMMANDES = "/commandes";
const DESSERTES = "/dessertes";
const LIEUX = "/lieux";
const ACTIVITES = "/activites";
const USERS = "/users";
const SETTING_FACTURES = "/setting-factures";
const USTOCKS = "/ustocks";
const SETTING_CAISSE = "/setting-caisses";
const SSB = "/ssb";
const APPROVISIONNEMENTS = "/approvisionnements";
const VENTES = "/ventes";
const INVENTAIRES = "/inventaires";
const RECUS = "/recus";
const STOCKS = "/stocks";
const MAGASINS = "/magasins";
const BENEFICIAIRES = "/beneficiaires";
const PAIEMENTS = "/paiements";

const crudLink = (base: string) => ({
  list: base,
  view: (id: string) => `${base}/${id}/view`,
  edit: (id: string) => `${base}/${id}/edit`,
  create: `${base}/create`,
});

export const LINKS = {
  personnels: {
    ...crudLink(PERSONNELS),
  },
  clients: {
    ...crudLink(CLIENTS),
    fiche: `${CLIENTS}/fiche`,
  },
  sites: {
    ...crudLink(SITES),
  },
  vehicule: {
    ...crudLink(VEHICULES),
  },
  commandes: {
    ...crudLink(COMMANDES),
  },
  dessertes: {
    ...crudLink(DESSERTES),
  },
  tournees: {
    ...crudLink(TOURNEES),
  },
  reporting: {
    base: REPORTING,
    ca: `${REPORTING}/ca`,
    operationnelle: `${REPORTING}/operationnelle`,
    vehicule: `${REPORTING}/vehicule`,
  },
  factures: {
    ...crudLink(FACTURES),
  },
  recus: {
    ...crudLink(RECUS),
  },
  incidents: {
    ...crudLink("/incidents"),
  },
  notifications: {
    ...crudLink("/notifications"),
  },
  auth: {
    login: "/login",
  },
  users: {
    ...crudLink(USERS),
  },
  roles: {
    ...crudLink("/roles"),
  },
  settings: {
    base: "/settings",
    villes: "/villes",
    situationGeographiques: "/situationgeographiques",
    pays: "/pays",
    list: "/centresregionaux",
    centresRegionaux: "/centresregionaux",
    centres: "/centres",
    prestations: "/prestations",
    secteurActivites: "/secteuractivites",
  },
  global: "/setting-global",
  lieux: {
    base: LIEUX,
    pays: `${LIEUX}/pays`,
    villes: `${LIEUX}/villes`,
    situationGeographiques: `${LIEUX}/situationgeographiques`,
    zones: `${LIEUX}/zones`,
    centresRegionaux: `${LIEUX}/centresregionaux`,
    centres: `${LIEUX}/centres`,
    devises: `${LIEUX}/devises`,
  },
  activites: {
    base: ACTIVITES,
    prestations: `${ACTIVITES}/prestations`,
    secteurActivites: `${ACTIVITES}/secteuractivites`,
    services: `${ACTIVITES}/services`,
    fonctions: `${ACTIVITES}/fonctions`,
    joursferies: `${ACTIVITES}/joursferies`,
  },
  settingFactures: SETTING_FACTURES,
  settingCaisse: {
    base: SETTING_CAISSE,
    coffreFort: `${SETTING_CAISSE}/coffrefort`,
  },
  ustocks: {
    base: USTOCKS,
    fournisseurs: `${USTOCKS}/fournisseurs`,
    magasins: `${USTOCKS}/magasins`,
    familleArticles: `${USTOCKS}/famillearticles`,
    articles: `${USTOCKS}/articles`,
  },
  ssb: {
    base: SSB,
    cassettes: `${SSB}/cassettes`,
    ticketTypes: `${SSB}/ticketTypes`,
    ticketCategories: `${SSB}/ticketCategories`,
  },
  dabs: {
    ...crudLink("/dabs"),
  },
  tickets: {
    ...crudLink("/tickets"),
  },
  approvisionnements: {
    ...crudLink(APPROVISIONNEMENTS),
  },
  ventes: {
    ...crudLink(VENTES),
  },
  stocks: {
    ...crudLink(STOCKS),
    viewArticle: `${STOCKS}/articles/:id`,
  },
  magasins: {
    ...crudLink(MAGASINS),
  },
  inventaires: {
    ...crudLink(INVENTAIRES),
  },
  beneficiaires: {
    ...crudLink(BENEFICIAIRES),
  },
  paiements: {
    ...crudLink(PAIEMENTS),
  },
  home: "/",
  billeteries: {
    base: "/billeteries",
    billets: `/billeteries/billets`,
    pieces: `/billeteries/pieces`,
  },
  collectes: {
    ...crudLink("/collectes"),
  },
  collectesTournees: {
    ...crudLink("/collectes-tournees"),
  },
  coffresForts: {
    ...crudLink("/coffres-forts"),
  },
  mouvements: {
    ...crudLink("/mouvements"),
  },
  pretris: {
    ...crudLink("/pretris"),
  },
  arretesCaisses: {
    ...crudLink("/arretes-caisses"),
  },
};

export const INIT_LIST = {
  data: [],
  total: 0,
  totalPage: 0,
  page: 1,
  totalInDatabase: 0,
};

export const INIT_FILTRE = {
  page: 1,
  perpage: 10,
};

export const DAYS = [
  {
    label: "Lundi",
    value: "lundi",
  },
  {
    label: "Mardi",
    value: "mardi",
  },
  {
    label: "Mercredi",
    value: "mercredi",
  },
  {
    label: "Jeudi",
    value: "jeudi",
  },
  {
    label: "Vendredi",
    value: "vendredi",
  },
  {
    label: "Samedi",
    value: "samedi",
  },
  {
    label: "Dimanche",
    value: "dimanche",
  },
];

export const CORPS = [
  { label: "Gendarmerie", value: "gendarmerie" },
  { label: "Police", value: "police" },
];

export const TYPES_DOCUMENTS = [
  { label: "CNI", value: "cni" },
  { label: "Passeport", value: "passeport" },
  { label: "Autre", value: "autre" },
];

export const CODE_CONVOYEUR = [
  CODE_FONCTION.CHEF_DE_BORD,
  CODE_FONCTION.AGENT_DE_GARDE,
  CODE_FONCTION.CHAUFFEUR,
];

export const ROLES = {
  admin: "admin",
  client: "client",
};

export const NOTIFICATION_TYPES = {
  incident: "App\\Notifications\\IncidentDesserteNotification",
  livraison: "App\\Notifications\\LivraisonDesserteNotification",
  priseEnCharge: "App\\Notifications\\PriseEnchargeDesserteNotification",
  finaliserPaiement: "App\\Notifications\\FinaliserPaiementNotification",
};

export const CODE_ETAT_TRAJET = {
  passe: "passe",
  non_passe: "non_passe",
  en_cours: "en_cours",
  annule: "annule",
  new_site: "new_site",
  ignore: "ignore",
};

export const CODE_PARAMETRAGE = {
  transport_facture: "transport-facture",
  parametre_global: "parametre-global",
};

export const APPROBATION_NIVEAU_1 = "approbation_niveau_1";
export const APPROBATION_NIVEAU_2 = "approbation_niveau_2";
export const ROLES_TOURNEES = [
  {
    label: "Niveau 1",
    code: "approbation_niveau_1",
  },
  {
    label: "Niveau 2",
    code: "approbation_niveau_2",
  },
];

export const TYPE_APPROVISIONNEMENT = {
  fournisseur: "appro_fournisseur",
  magasin: "appro_inter_magasin",
  client: "appro_client",
};

export const PRESTATIONS = [
  {
    label: "Transport de fond",
    value: "TDF",
  },
  {
    label: "Vente de matériel",
    value: "VENTE",
  },
  {
    label: "Paiement sur site",
    value: "PAIEMENT",
  },
  {
    label: "Collecte de fonds",
    value: "COLLECTE",
  },
  {
    label: "SSB",
    value: "SSB",
  },
];
