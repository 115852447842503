import { EditTwoTone } from "@ant-design/icons";
import { Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { TableSimple } from "components";
import { useCrudModal } from "hooks/useCrudModal";
import { PageTitle } from "pages/common";
import { useMemo } from "react";
import { CoffreFortResource } from "types/caisse.type";
import { CoffreFortModal } from "./CoffreFortModal";
import { useQuery } from "@tanstack/react-query";
import { coffreFortService } from "services";

export const ListCoffreFort = () => {
  const theme = useTheme();
  const crudModal = useCrudModal<CoffreFortResource>();

  const { data: results, isLoading } = useQuery({
    queryKey: ["coffreforts"],
    queryFn: () => {
      return coffreFortService.findAll();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Nom",
        accessor: "libelle",
      },
      {
        Header: "Numéro",
        accessor: "numero",
      },
      {
        Header: "Centre",
        accessor: "centre.libelle",
      },
      {
        Header: "Actions",
        accessor: "actions",
        style: { textAlign: "right" },
        className: "text-right",
        Cell: ({ row: { original } }: any) => (
          <div style={{ textAlign: "right" }}>
            <Tooltip title="Modifier la catégorie">
              <IconButton
                color="error"
                size="large"
                onClick={crudModal.onUpdate(original)}
              >
                <EditTwoTone twoToneColor={theme.palette.error.main} />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    [crudModal, theme.palette.error.main]
  );

  const closeModal = () => {
    crudModal.reset();
  };

  return (
    <div>
      <PageTitle
        title="Coffre fort"
        subtitle="Consulter et gérer les coffres forts"
        sx={{ padding: "1.5rem 0" }}
        right={
          <Button variant="contained" onClick={crudModal.onCreate()}>
            Créer un coffre fort
          </Button>
        }
      />

      <TableSimple
        columns={columns}
        data={results?.data || []}
        loading={isLoading}
        withPagination
        title="Liste des coffres forts"
      />

      {crudModal.isCreateOrUpdate && (
        <CoffreFortModal
          closeModal={closeModal}
          value={crudModal.action?.element}
        />
      )}
    </div>
  );
};
