// assets

import { useMemo } from "react";
import { LINKS } from "utils/constant";
import UtiliataireTemplate from "./UtiliataireTemplate";

export const CaisseNavigation = () => {
  const links = useMemo(() => {
    return [
      {
        label: "Coffre forts",
        to: LINKS.settingCaisse.coffreFort,
      },
    ];
  }, []);

  return <UtiliataireTemplate links={links} />;
};
